import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private clientId: string;
  private clientSecret: string;
  private authUrl: string;
  private grantType: string;
  private logoutSubject = new Subject<boolean>();
  private userInfoSubject = new Subject<any>();

  logout$ = this.logoutSubject.asObservable();
  userInfoSubject$ = this.userInfoSubject.asObservable();

  constructor(private http: HttpClient) {
    this.clientId = environment.omsConfig.clientId;
    this.clientSecret = environment.omsConfig.clientSecret;
    this.authUrl = environment.omsConfig.authUrl;
    this.grantType = environment.omsConfig.grantType;

    if (!this.clientId || !this.clientSecret || !this.authUrl) {
      throw new Error('Environment variables for authentication are not properly configured.');
    }
  }

  public logout() {
    this.logoutSubject.next(true); 
  }

  public setUserInfo(userInfo: any) {
    this.userInfoSubject.next(userInfo); 
  }

  public getToken(): Observable<string> {
    const params = new HttpParams()
      .set('grant_type', this.grantType)
      .set('client_id', this.clientId)
      .set('client_secret', this.clientSecret);

    return this.http.get<string>(this.authUrl, { params }).pipe(
      map((response:any) => response.access_token as string),
      catchError((error: Error) => {
        console.error('Error fetching token:', error);
        return throwError(error);
      })
    );
  }
}
