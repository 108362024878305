import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '@app/Services/auth.service';
import { MenuItem } from '@app/Shared/interfaces/iMenu';
import { Subscribable, Subscription } from 'rxjs';

@Component({
    selector: 'app-left-nav',
    templateUrl: './left-nav.component.html',
    styleUrls: ['./left-nav.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LeftNavComponent implements OnInit, OnDestroy {
    @Input() mini: boolean = false;

    private subscription: Subscription = new Subscription();
    public userInfo: any;

    constructor(private readonly authService: AuthService) {}
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngOnInit(): void {
        this.subscription.add(
            this.authService.userInfoSubject$.subscribe((info) => {
                this.userInfo = info;
            })
        );
    }

    menuItems: MenuItem[] = [
        {
            title: 'Data Explorer',
            page: '/data-explorer',
            iconClass: 'database',
        },
        { title: 'Reports', page: '/reports', iconClass: 'lab_profile' },

        { title: 'Settings', page: '/settings', iconClass: 'settings' },
    ];

    menuItemsFooter: MenuItem[] = [{ title: 'Log out', page: '/logout', iconClass: 'logout' }];

    userLogo: string = '/assets/images/user-logo.png';
}
