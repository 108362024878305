<div class="td-sidenav" [ngClass]="{'is-mini': mini}">
  <div class="user-details py-5">
    <img src={{userLogo}} alt="User Logo" class="user-logo">
    <div *ngIf="userInfo" class="user-info">
      <div class="user-name" [ngClass]="{'animate__animated animate__fadeIn': !mini}">{{userInfo.first_name + ' '+ userInfo.last_name}}</div>
      <div class="company-name mat-accent" [ngClass]="{'animate__animated animate__fadeIn': !mini}">{{userInfo.account_type}}</div>
    </div>
  </div>

  <mat-nav-list class="app-menu pt-5">
    <mat-list-item *ngFor="let item of menuItems" [routerLink]="item.page" routerLinkActive="active-link"
      matTooltipClass="app-tooltip" matTooltip="{{item.title}}" matTooltipPosition="after" [matTooltipDisabled]="!mini">
      <div class="td-list-text" [ngClass]="{'animate__animated animate__fadeIn': !mini}">
        <mat-icon class="app-menu-icon material-symbols-outlined mr-2">
          {{item.iconClass}}
        </mat-icon>
        <span>{{ item.title }}</span>
      </div>
    </mat-list-item>
  </mat-nav-list>

  <mat-divider></mat-divider>

  <div class="bottom-section pb-2">
    <mat-nav-list class="app-sub-menu">
      <mat-list-item href="#" *ngFor="let item of menuItemsFooter" [routerLink]="item.page">
        <div class="td-list-text" [ngClass]="{'animate__animated animate__fadeIn': !mini}">
          <mat-icon class="app-sub-menu-icon material-symbols-outlined mr-2">
            {{item.iconClass}}
          </mat-icon>
          <span>{{ item.title }}</span>
        </div>
      </mat-list-item>
    </mat-nav-list>
  </div>
</div>
